.landing {
  height: 860px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.landing--img-container {
  --img-size: 400px;
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  /* transition: opacity 0.5s; */
}

.landing--img-wrapper {
  --img-size: 400px;
  position: relative;
  width: var(--img-size); /* Use the same size as the image */
  height: var(--img-size);
  border-radius: 50%; /* If you want to keep the image circle shape */
  overflow: hidden; /* This ensures the shutter effect does not go outside the bounds of the image */
  /* transition: opacity 0.5s; */
 }

.landing--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* transition: opacity 0.5s; */
  border-radius: 50%;
}

.landing--container-left {
  flex: 35%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.lcl--content {
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing--social {
  font-size: 35px;
  margin: 0 1rem;
  transition: transform 0.5s;
}

.landing--social:hover {
  transform: scale(1.2);
}

.landing--container-right {
  flex: 65%;
  height: 100%;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.lcr--content {
  width: 45%;
  margin-right: 7rem;
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -0.85rem;
  opacity: 0.8;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.25rem;
  line-height: 110%;
  margin: 1rem 0;
}

.lcr--content p {
  margin-top: 1.45rem;
  font-weight: 500;
  font-size: 1.15rem;
  opacity: 0.7;
}

.lcr-buttonContainer {
  margin-top: 2rem;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "var(--primaryFont)", sans-serif;
}

.title-container {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 1100px) {
  .landing--img-container {
    --img-size: 350px;
  }

  .landing--img-wrapper {
    --img-size: 350px;
  }
  
  .landing--img {
    --img-size: 350px;
  }

  .lcl--content {
    margin: 2rem;
  }

  .lcr--content h6 {
    font-size: 1.1rem;
  }

  .lcr--content h1 {
    font-size: 3rem;
  }

  .lcr--content p {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .landing--img-container {
    --img-size: 300px;
  }

  .landing--img-wrapper {
    --img-size: 300px;
  }

  .landing--img {
    --img-size: 300px;
  }

  .landing--social {
    font-size: 30px;
  }
  .lcr--content {
    width: 60%;
    margin-top: 10%;
    margin-right: 6%;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
  .lcr-buttonContainer button:nth-child(2) {
    display: none;
  }
}

@media (max-width: 725px) {
  .landing--container {
    flex-direction: column;
    justify-content: space-between;
  }
  
  .landing--img-container {
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }

  .landing--container-left {
    flex: initial;
    width: 100%;
    height: 30%;
  }
  .landing--social {
    display: none;
  }
  .landing--container-right {
    box-sizing: border-box;
    padding: 0 2rem;
    flex: initial;
    height: 70%;
    width: 100%;
    background-color: #eaeaea;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
  .lcr--content {
    margin-top: 20%;
    margin-right: initial;
    width: 100%;
  }
  .lcr--content h6 {
    font-size: 1rem;
    text-align: center;
  }
  .lcr--content h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  .lcr--content p {
    font-size: 0.95rem;
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }
  .lcr-buttonContainer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lcr-buttonContainer button {
    margin-top: -20%;
  }

  .title-container {
    justify-content: center;
  }

  .landing--img-container {
    --img-size: 300px;
  }

  .landing--img-wrapper {
    --img-size: 300px;
  }
  
  .landing--img {
    --img-size: 300px;
  }

  .landing {
    height: 775px;
  }
  .landing--container-left {
    height: 225px;
  }
  .landing--container-right {
    height: 550px;
  }

  .lcr--content {
    margin-top: 150px;
  }

  .landing--img-container {
    top: 225px;
  }

}

@media (max-width: 600px) {
  .landing--img-container {
    --img-size: 200px;
  }

  .landing--img-wrapper {
    --img-size: 200px;
  }
  
  .landing--img {
    --img-size: 200px;
  }
}


@media (max-width: 500px) {
  /* .lcr--content {
    margin-top: 35%;
  } */

  .landing--img-container {
    --img-size: 200px;
  }

  .landing--img-wrapper {
    --img-size: 200px;
  }
  
  .landing--img {
    --img-size: 200px;
  }

  .landing {
    height: 775px;
  }
  .landing--container-left {
    height: 225px;
  }
  .landing--container-right {
    height: 550px;
  }

  .lcr--content {
    margin-top: 150px;
  }

  .landing--img-container {
    top: 225px;
  }

}
@media (max-width: 400px) {
  .landing {
    height: 875px;
  }
  .landing--container-left {
    height: 225px;
  }
  .landing--container-right {
    height: 650px;
  }
  .lcr--content {
    margin-top: 100px;
  }
  .landing--img-container {
    top: 225px;
  }
}

@media (max-width: 350px) {
  .lcr--content {
    margin-top: 170px;
  }
}

@media (max-width: 300px) {
  .lcr--content > h1 {
    font-size: 2rem;
  }

  .landing--img-container {
    --img-size: 150px;
  }

  .landing--img-wrapper {
    --img-size: 150px;
  }
  
  .landing--img {
    --img-size: 150px;
  }

  .landing {
    height: 887px;
  }
  .landing--container-left {
    height: 87px;
  }
  .landing--container-right {
    height: 800px;
  }
  .navbar--container {
    margin-top: 0;
  }

  .navbar--container > h1 {
    display: none;
  }

  .landing--img-container {
    top: 180px;
  }

  .lcr--content {
    margin-top: 200px;
  }

  
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .landing {
    height: 100%;
  }

  .landing--img-container {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }

  .landing--img-wrapper {
    --img-size: 150px;
  }

  .landing--img {
    --img-size: 150px;
  }
}

.shutter-left, .shutter-right {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--shutterColor); /* Ensure this variable is dynamically updated in your JS */
  z-index: 2;
  width: 0%; /* Start with no width */
  height: 100%; /* Full height */
}

.shutter-left {
  left: 0;
}

.shutter-right {
  right: 0;
}

@keyframes closeShutterHorizontal {
  from {
    width: 0%;
  }
  to {
    width: 50%; /* Adjust to cover half the image from each side */
  }
}

@keyframes openShutterHorizontal {
  from {
    width: 50%;
  }
  to {
    width: 0%;
  }
}

.shutter-left.closeShutter, .shutter-right.closeShutter {
  animation: closeShutterHorizontal 0.2s forwards;
}

.shutter-left.openShutter, .shutter-right.openShutter {
  animation: openShutterHorizontal 0.2s forwards;
}

 @keyframes closeShutterVertical {
    from {
      height: 0%;
    }
    to {
      height: 50%; /* Adjust to cover half the container from top and bottom */
    }
  }

  @keyframes openShutterVertical {
    from {
      height: 50%;
    }
    to {
      height: 0%;
    }
  }

  /* .shutter-left.closeShutter, .shutter-right.closeShutter {
    animation: closeShutterVertical 0.2s forwards;
  }
  
  .shutter-left.openShutter, .shutter-right.openShutter {
    animation: openShutterVertical 0.2s forwards;
  }
 */


@keyframes dropIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes dropOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.character {
  animation: dropIn 0.5s forwards;
}

.old-character {
  animation: dropOut 0.5s forwards;
}
