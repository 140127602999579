.experience{
    min-height: 900px;
}

.experience-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.experience-description {
    width: 100%;
    flex: 0.65;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 2rem;
}

.experience-description > h1 {
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.card-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.experience-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    cursor: pointer;
    flex-wrap: wrap;
    z-index: 1;
    transition: opacity 2s ease;
    /* order: 0; */
}

.experience-card.expanded-card {
    width: 100%;
    height: 560px;
    z-index: 10;
    opacity: 1;
    transition: height 3s ease, opacity .5s ease;  /* Ensure transition includes order */
}

.experience-card.hidden-card {
    opacity: 0;
    transition: opacity 0.2s ease,;
    pointer-events: none;
    /* Delay the transition of expanded card until hidden cards have disappeared */
    /* transition-delay: 0.5s; */
}

.experience-card-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    cursor: pointer;
    transition: opacity 1s ease;
    
}

.experience-card-wrapper.expanded-card {
    height: 560px;
    z-index: 10;
    animation: expandedCardFade 1s ease;
}

.experience-card-wrapper.hidden-card {
    opacity: 0;
    /* overflow: hidden; */
    pointer-events: none;
    transition: opacity .5s ease;
}


.expcard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.expcard-img > img {
    width: 36px;
    pointer-events: none;
}

.experience-details {
    margin-left: 0.6rem;
}

.experience-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.experience-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.experience-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.experience-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35;
    margin-left: 7%;
    margin-top: 1rem;
    pointer-events: none;
}

.experience-image > img {
    width: 100%;
    pointer-events: none;
}

.experience-details-expanded {
    font-size: 1.15rem;
    font-family: var(--primaryFont);
    /* margin-top: -100px; */
    animation: expandedTextFade 3s ease;
}

.experience-details-expanded > h4 {
    font-size: 1.225rem;
    font-weight: 600;
    opacity: 1;
    margin-top: 15px;
}

.highlight-list {
    margin-bottom: 10px;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
}

.highlight-list li {
    margin-right: 15px;
    padding: 0;
    position: relative; /* Add relative positioning */
}

.highlight-list li:not(:last-child)::after {
    content: ""; /* Add content for pseudo-element */
    position: absolute; /* Position the circle absolutely */
    width: 5px;
    height: 5px;
    background-color: #e67c00;
    border-radius: 50%;
    right: -10px; /* Adjust positioning to be between list items */
    top: 50%; /* Adjust positioning to center the circle vertically */
    transform: translateY(-50%);
}

.details-list {
    margin-bottom: 10px;
    padding-left: 20px;
    list-style-type: disc;
}

.details-list li {
    margin-bottom: 10px;
}

/* @media (min-width: 1150px) and (max-width: 1380px) {
    .experience-details-expanded {
        font-size: 1rem;
    }
} */

@media (min-width: 1150px) and (max-width: 1380px) {
    .experience-details-expanded > h4 {
        font-size: 1.1rem;
        font-weight: 600;
        opacity: 1;
    }

    .experience-details-expanded {
        font-size: .95rem;
    }
}

@media (min-width: 992px) and (max-width: 1150px) {
    .experience-details-expanded > h4 {
        font-size: 1rem;
        font-weight: 600;
        opacity: 1;
    }

    .experience-details-expanded {
        font-size: .9rem;
    }
}

@media screen and (max-width: 992px) {
    .experience {
       height: 100vh;
    }
    .experience-image {
        display: none;
    }
    .experience-description {
        flex: 1;
        margin: auto;
    }    
    .experience-description > h1{
        font-size: 3.2rem;
    }
    .experience-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 820px) {
    .experience-description > h1{
        font-size: 3rem;
        margin-bottom: 1rem;
        align-self: center;
    }
    .experience-description {
        padding: 3rem 1rem 1rem 1rem; 
    }
    .experience-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
    }
    .expcard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .expcard-img > img {
        width: 30px;
    }
    .experience-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .experience-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .experience-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }

    .experience-card-wrapper {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .experience-details-expanded {
        font-size: 1rem;
    }

    .experience-details-expanded > h4 {
        font-size: 1.1rem;
        font-weight: 600;
        opacity: 1;
    }

    .experience-description {
        padding: 1rem 1rem 1rem 1rem; 
    }

    .experience-card-wrapper {
        width: 85%; /* Adjust width as necessary */
        margin: 0 auto; /* Center the card horizontally */
        height: auto; /* Adjust height as necessary, auto for content height */
        padding: 0rem; /* Adjust padding to ensure content is not too squeezed */
        align-self: center; /* Center the card horizontally */
    }

    .experience-card {
        height: 100px;
    }
    .experience {
        height: 700px;
        min-height: 700px;
    }
    
}

@media screen and (max-width: 680px) {
    
}

@media screen and (max-width: 600px) {

    .experience-description > h1{
        font-size: 2.5rem;
    }

    .experience-details-expanded {
        font-size: .9rem;
    }

    .experience-details-expanded > h4 {
        font-size: 1rem;
        font-weight: 600;
        opacity: 1;
    }

    .highlight-list {
        margin-bottom: 0px;
        padding-left: 0px;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
    }
    
    .details-list {
        margin-bottom: 5px;
        list-style-type: none;
        padding-left: 17px;
        list-style-type: disc;
    }
}

@media screen and (max-width: 515px) {

    .experience-details-expanded {
        font-size: .8rem;
    }

    .experience-details-expanded > h4 {
        font-size: .9rem;
        font-weight: 600;
        opacity: 1;
    }
}


@media screen and (max-width: 400px) {
    
    .experience-details > h4{
        font-size: .85rem;
    }

    .experience-details > h5{
        font-size: .85rem;
    }

    .experience-details-expanded {
        font-size: .73rem;
    }

    .experience-details-expanded > h4 {
        font-size: .83rem;
        font-weight: 600;
        opacity: 1;
    }

    .expcard-img {
        border-radius: 50%;
        width: 25px;
        height: 25px;
    }
    
    .expcard-img > img {
        width: 15px;
    }


    .highlight-list {
        margin-bottom: 10px;
        padding-left: 0px;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
    }
    
    .details-list {
        margin-bottom: 0px;
        list-style-type: none;
        padding-left: 10px;
        list-style-type: disc;
    }

    .details-list li {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 350px) {

    .experience-details-expanded {
        font-size: .6rem;
    }

    .experience-details-expanded > h4 {
        font-size: .7rem;
        font-weight: 600;
        opacity: 1;
    }
}

@media screen and (max-width: 300px) {
    .experience-description {
        align-items: center; /* Center children horizontally in the flex direction */
        justify-content: center; /* Center children vertically in the flex direction */
        padding: 5rem 0rem 0rem 0rem; /* Adjust padding as needed */
    }

    .experience-card-wrapper {
        width: 85%; /* Adjust width as necessary */
        margin: 0 auto; /* Center the card horizontally */
        height: auto; /* Adjust height as necessary, auto for content height */
        padding: 0rem 1rem 0rem 1rem; /* Adjust padding to ensure content is not too squeezed */
        align-self: center; /* Center the card horizontally */
    }

    .card-section {
        margin-top: 1rem;
    }

    .expcard-img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    
    .expcard-img > img {
        width: 15px;
    }


    .experience-details > h4, .experience-details > h5 {
        font-size: 0.9rem; /* Adjust the font size as necessary */
    }

    .experience-description {
        padding-top: 2rem;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}

@keyframes expandedCardFade {
    0% { opacity: .1; } /* Start with full opacity */
    10% { opacity: .15; }
    20% { opacity: .2; }
    30% { opacity: .3; }
    40% { opacity: .4; }
    50% { opacity: .5; } /* Fade out at 50% of the animation duration */
    60% { opacity: .6; }
    70% { opacity: .7; }
    80% { opacity: .8; }
    90% { opacity: .9; }
    100% { opacity: 1; } /* Fade back in at the end */
  }

  @keyframes expandedTextFade {
    0% { opacity: 0; } /* Start with full opacity */
    10% { opacity: 0; }
    20% { opacity: 0; }
    30% { opacity: 0; }
    40% { opacity: .1; }
    50% { opacity: .3; } /* Fade out at 50% of the animation duration */
    60% { opacity: .5; }
    70% { opacity: .7; }
    80% { opacity: .8; }
    90% { opacity: .9; }
    100% { opacity: 1; } /* Fade back in at the end */
  }