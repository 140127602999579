.interestsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.interests {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensure alignment to the left */
    padding: 0rem 2rem; /* Adjust starting location of carousels */
    width: 100%; /* Adjust if necessary to prevent overflow */
    box-sizing: border-box;
}


.interestsHeader {
    margin-bottom: 1rem; /* Ensure consistent spacing above each carousel */
    display: flex; 
    flex-direction: row; /* Change to column to stack children vertically */
    align-items: center;
    position: relative; /* Added for positioning context */
}

.interestsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
}

.imagesContainer {
    position: relative;
    width: 90px; /* Set to the width of your images */
    height: 90px; /* Set to the height of your images */
}

.imagesContainer img {
    position: absolute;
    top: 0;
    left: 5px;
    height: 90px;
    width: 90px; /* Ensure the image covers the container */
}

.interestsHeader img.fade:hover {
    opacity: 0; /* Fades out the top image on hover */
}

#orangeDice {
    z-index: 1; /* Ensure the orange dice is on top */
    opacity: 0; /* Hide the orange dice */
}

#bwDice {
    z-index: 0; /* Ensure the black and white dice is below the orange dice */
    opacity: 1; /* Show the black and white dice */
}

#orangeDice:hover {
    z-index: 2; /* Ensure the orange dice is on top when hovered */
    opacity: 1; /* Show the orange dice when hovered */
    transition: opacity 0.2s ease-out,  height 0.2s ease, width 0.2s ease;
    cursor: pointer;
    background-color: #EAEAEA;
    height: 91px;
    width: 91px;
    
}

.interests--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    width: 350px;
    height: 350px;
    margin: 0rem 5rem 5rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* This will center the content vertically */
    padding: 0;
    transition: 300ms ease-in-out;
    position: relative;
    overflow: hidden;
}

.interests--box:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    
}

.image-wrapper {
    width: 100%;
    height: 65%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-bottom: #21212180 4px solid;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    transform: perspective(800px); /* Added for 3D effect */
    transform-origin: bottom;
    transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out;
}

.interests--box:hover .image-wrapper {
    transform: perspective(800px) rotateX(0deg) translateY(-20px); /* Less tilt and slight lift on hover */
    border-bottom: #21212180 4px solid;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
}

.interests--box > .image-wrapper > img {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    position: absolute;
    top: 0; /* Align with the top of the container */
    left: 0; /* Align with the left of the container */
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    transition: bo
}

.interests--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    text-decoration: underline;
}

.interests--body {
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap; */
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 2rem;
}

.interest--details {
    width: 90%; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 65%; /* Adjust based on .image-wrapper height */
}

.interest-list {
    margin-top: 15px;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center; /* Centers flex items horizontally */
    width: 100%; /* Ensures the list occupies the full width of its parent */
    font-style: italic;
    width: 70%; /* Adjust width as needed */
}

.interest-list li {
    margin: 0 7.5px;
    padding: 0;
    position: relative; /* Add relative positioning */
}

.interest-list li:not(:last-child)::after {
    content: ""; /* Add content for pseudo-element */
    position: absolute; /* Position the circle absolutely */
    width: 5px;
    height: 5px;
    background-color: #e67c00;
    border-radius: 50%;
    right: -10px; /* Adjust positioning to be between list items */
    top: 50%; /* Adjust positioning to center the circle vertically */
    transform: translateY(-50%);
}

@media (max-width: 1500px) {
    .interests--box {
        margin: 0rem 3rem 5rem 3rem;
        align-items: center;
        justify-content: space-evenly;
    }
    .interest--details {
        margin-top: 180px;
    }
}


@media (max-width: 1400px) {
    .interests--box {
        margin: 0rem 1rem 5rem 1rem;
        align-items: center;
        justify-content: space-evenly;
    }
    .interest--details {
        margin-top: 180px;
    }
}

@media (max-width: 1300px) {
    .interests--box {
        width: 300px;
        height: 300px;
        /* margin: 0rem 3rem 5rem 3rem; */
        align-items: center;
        justify-content: space-evenly;
    }
    .interest--details {
        margin-top: 160px;
        height: 40px
    }
    .interests--box > .image-wrapper > img {
        height: 225px;
    }

    .interests--box h3 {
        font-size: 14px;
        margin-top: 0px;
    }

    .interest-list {
        margin-top: 5px;
        width: 75%;
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .interests--box {
        margin: 0rem 2rem 5rem 2rem;
    }
    .interests--box > .image-wrapper > img {
        height: 250px;
    }
}

@media (max-width: 1100px) {
    .interests--box {
        margin: 0rem 1rem 5rem 1rem;
        align-items: center;
        justify-content: space-evenly;
    }
}



@media (max-width: 1000px) {
    .interests--box {
        width: 250px;
        height: 250px;
    }

    .interests--body {
        margin-top: 2rem;
    }

    .interests--box > .image-wrapper > img {
        height: 225px;
    }

    .interests--box h3 {
        font-size: 14px;
        margin-top: 0px;
    }

    .interest-list {
        margin-top: 5px;
        width: 75%;
        font-size: 12px;
    }

    .interest--details {
        margin-top: 140px;
    }

    .interestsHeader {
        margin-top: 4rem;
        justify-content: center;
        width: 100%;
    }

}

@media (max-width: 800px) {
    .interests {
        padding: 0rem 1.5rem;
    }

    .interestsHeader {
        margin-top: 4rem;
        justify-content: center;
        width: 100%;
    }

    .interestsHeader h2 {
        font-size: 2.3rem;
        text-align: center;
        /* width: 50%; */
    }
   
    .imagesContainer img {
        left: 5px;
    }

    .image-wrapper {
        width: 100%;
        height: 60%;
        border-bottom: 1px solid;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
    }

    .interests--box > .image-wrapper > img {
        border: 0;
    }


    .interests--box {
        margin: 0rem 0rem 3rem 0rem;
    }

    .interest-list {
        width: 90%;
        font-size: 10px;
    }

    .interest--details {
        margin-top: 55%;
    }
    .interests--body {
        flex-direction: column;
        margin-top: 2rem;
    }
}


@media (max-width: 700px) {
    .interests {
        padding: 0rem 1rem;
    }
}

@media (max-width: 600px) {
    .interests {
        padding: 0rem .5rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 500px) {
    .interests {
        padding: 0rem 0rem;
    }

    .interestsHeader {
        align-items: center; /* Center align the flex items */
        justify-content: center; /* Center vertically if needed */
        text-align: center; /* Center the text within the heading */
    }

    .interestsHeader h2 {
        /* If the heading doesn't naturally collapse into two lines, you might need to adjust font-size or container width */
        font-size: 2rem; /* Adjusted for smaller screens to help text wrap */
    }

    .imagesContainer {
        /* Center the image container */
        left: 10%; /* Center horizontally */
        transform: translateX(-50%); /* Adjust alignment */
        position: relative; /* Adjust if necessary */
    }

    .interests--box, .interests--body, .interest--details, .interest-list {
        /* Additional adjustments to center and accommodate layout within the interests container */
        align-items: center; /* Center align the flex items for these classes */
        text-align: center; /* Ensure the text is centered */
        justify-content: center; /* Center content vertically */
    }

}

@media (max-width: 400px) {
    .interests--box {
        border-radius: 10px;
    }

    .interests--box h3 {
        font-size: 16px;
    }

    .interests--box li {
        font-size: 12px;
    }

    .interestsHeader h2 {
        width: 50%;
    }

    .imagesContainer img {
        height: 80px;
        width: 80px;
        left: 40px;
    }
    
}

@media (max-width: 300px) {

    .interests {
        padding: 0rem .5rem;
        margin-bottom: 0rem;
    }

    .image-wrapper > img {
        border: 0;
    }

    /* .imagesContainer img {
        height: 80px;
        width: 80px;
        left: 40px;
    } */


    .interests--box h3 {
        font-size: 14px;
        margin-top: 0px;
    }

    .interest-list {
        margin-top: 5px;
        width: 80%;
        font-size: 10px;
    }

    .interest--details {
        margin-top: 55%;
    }
}

@keyframes shake {
    0% { transform: translate(-3px, -3px) rotate(5deg); }
    10% { transform: translate(3px, 3px) rotate(10deg); }
    20% { transform: translate(-3px, -3px) rotate(4deg); }
    30% { transform: translate(3px, 3px) rotate(8deg); }
    40% { transform: translate(-2px, -2px) rotate(3deg); }
    50% { transform: translate(2px, 2px) rotate(6deg); }
    60% { transform: translate(-1px, -1px) rotate(2deg); }
    70% { transform: translate(1px, 1px) rotate(4deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 1px) rotate(2deg); }
    100% { transform: translate(0, 0) rotate(0); }
}

.shake {
    animation: shake 0.5s; /* Adjust time as needed */
}

@keyframes fadeOutToBottom {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(100%);
    }
}

@keyframes fadeInFromTop {
    0% {
        display: None;
        opacity: 0;
        transform: translateY(-100%);
    }
    20% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.fadeOutToBottom {
    animation: fadeOutToBottom 0.5s ease-in-out forwards;
}

.fadeInFromTop {
    animation: fadeInFromTop 0.5s ease-in-out forwards;
    opacity: 0
}
