.singleProject {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.3);
    width: 350px;
    height: 500px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Changed from space-between to center for consistent alignment */
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin-left: 50px;
    margin-right: 50px;
    transition: transform 0.5s ease; /* Added for a smooth hover effect */
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 100%;
    height: 60%;
    transition: opacity 0.7s ease;
}

.singleProject:hover img {
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-around; /* Changed to space-around for better spacing */
    width: 100%;
}

.project--showcaseBtn > a {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.5s ease;
}

.singleProject .project--desc {
    position: absolute;
    width: 95%;
    height: 300px;
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%) scale(0); /* Adjusted for central alignment and hide initially */
    padding: 15px;
    border-radius: 20px;
    transition: transform 0.9s ease;
    line-height: 110%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
    background-color: #EAEAEA; /* Assuming a background color for visibility */
}

.singleProject:hover .project--desc {
    transform: translate(-50%, -55%) scale(1); /* Show content on hover with scale */
}

.singleProject .project--desc > p {
    margin-bottom: 20px; /* Spacing between description and tag list */
    text-align: justify;
    width: 90%;
    height: 100%;
}

.projects-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the tags */
    gap: 10px; /* Spacing between tags */
    width: 80%
}

.projects-list > li {
    background-color: #EAEAEA; /* Light background for tags */
    border-radius: 5px;
    padding: 5px 10px;
    color: var(--primary);
    margin: 0 7.5px;
    padding: 0;
    position: relative; /* Add relative positioning */
    font-size: 12px;
}

.projects-list {
    margin-top: 15px;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center; /* Centers flex items horizontally */
    width: 100%; /* Ensures the list occupies the full width of its parent */
    font-style: italic;
    width: 100%; /* Adjust width as needed */
}

.projects-list li:not(:last-child)::after {
    content: ""; /* Add content for pseudo-element */
    position: absolute; /* Position the circle absolutely */
    width: 5px;
    height: 5px;
    background-color: #e67c00;
    border-radius: 50%;
    right: -10px; /* Adjust positioning to be between list items */
    top: 50%; /* Adjust positioning to center the circle vertically */
    transform: translateY(-50%);
}

hr {
    color: #e67c00;
    width: 50% ;
    text-align: center;
    }

@media screen and (max-width: 800px) {
    .singleProject {
        box-shadow: None;
        height: 240px;
        width: 200px;
        margin-left: 12px;
        margin-right: 12px;
        border-radius: 5px;
        padding: 1rem;
        transform: none; /* Reset transform */
        transition: none; /* Optional: Remove transition to avoid any hover effect */
    }

    .singleProject img {
        opacity: 1 !important; /* Prevent image opacity change on hover */
    }

    /* Ensure project description remains hidden and doesn't react to hover */
    .singleProject .project--desc {
        transform: translate(-50%, -50%) scale(0); /* Keep description hidden */
        transition: none !important; /* Prevent any transition effects on hover */
    }

    .singleProject:hover .project--desc {
        transform: translate(-50%, -50%) scale(0) !important; /* Ensure description stays hidden on hover */
    }


    .projectContent > h2 {
        font-size: 1.1rem;
    }
    
    .project--showcaseBtn > a {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 10;
        width: 100%;
        height: 100%;    
    }

    .projects--viewAll {
        position: relative;
        margin-top: 2rem;
    }
    
}

@media screen and (max-width: 700px) {
    .singleProject {
        height: 210px;
        width: 180px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .projectContent > h2 {
        font-size: 1rem;
    }
    
}

@media screen and (max-width: 600px) {
    .singleProject {
        height: 180px;
        width: 150px;
        margin-left: 9px;
        margin-right: 9px;
    }
    .projectContent > h2 {
        font-size: .8rem;
    }
    
}

@media screen and (max-width: 500px) {
    .singleProject {
        height: 150px;
        width: 120px;
        margin-left: 8px;
        margin-right: 8px;
    }

    .projectContent > h2 {
        font-size: .7rem;
    }
    
}

@media screen and (max-width: 400px) {
    .singleProject {
        height: 120px;
        width: 90px;
        margin-left: 8px;
        margin-right: 8px;
    }

    .projectContent > h2 {
        font-size: .6rem;
    }
    
}

@media screen and (max-width: 300px) {
    .singleProject {
        height: 100px;
        width: 80px;
        margin-left: 7px;
        margin-right: 7px;
    }
}