.aboutCarousel {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.skills {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensure alignment to the left */
    padding: 0rem 2rem; /* Adjust starting location of carousels */
    width: 100%; /* Adjust if necessary to prevent overflow */
    box-sizing: border-box;
}

.skillsHeader {
    margin-bottom: 1rem; /* Ensure consistent spacing above each carousel */
}

.skillsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
}

.skillsContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 2rem;
    padding: 0 2rem;
}

.skill--scroll {
    width: 100%; /* Ensure it takes up the intended width without overflow */
    overflow: hidden; /* Hide any overflow */
}


.skill--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 140px;
    height: 140px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
}

.skill--box:hover  {
    transform: scale(1.15);
}


.skill--box > img {
    height: 40px;
    pointer-events: none;
}


.skill--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
}


.marquee {
    padding: 0rem 0rem 3rem 0;
}


@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {

    /* .skillsHeader h2 {
        font-size: 3.2rem;
    } */
    .skill--box {
        width: 150px;
        height: 150px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 45px;
    }


    .skill--box h3 {
        font-size: 20px;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 820px) {
    .skills {
        padding: 0rem 1.5rem;
    }

    .skillsHeader {
        width: 100%;
    }
    .skillsHeader h2 {
        align-self: center;
    }
    
    .marquee {
        padding: 0rem 0rem 2.5rem 0;
    }
}

@media screen and (max-width: 700px) {
    .skill--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 40px;
    }

    .skill--box h3 {
        font-size: 18px;
        margin-top: 1rem;
    }

    .marquee {
        padding: 2rem 0;
    }

    .skills {
        padding: 0rem 1rem;
    }
}


@media screen and (max-width: 600px) {

    .skill--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 40px;
    }

    .skillsHeader h2 {
        font-size: 2.5rem;
    }

    .skill--box h3 {
        font-size: 18px;
        margin-top: 1rem;
    }

    .marquee {
        padding: 1.5rem 0;
    }

    .skills {
        padding: 0rem .5rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .skill--box {
        width: 110px;
        height: 110px;
        margin: 1rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 30px;
    }

    .skillsHeader h2 {
        font-size: 2rem;
    }

    .skill--box h3 {
        font-size: 16px;
        margin-top: 1rem;
    }

    .marquee {
        padding: 1rem 0;
    }

    .skills {
        padding: 0rem 0rem;
    }
}

@media screen and (max-width: 400px) {
    .skill--box {
        width: 100px;
        height: 100px;
        margin: .8rem;
        padding: 1.8rem 1rem;
    }

    .skill--box > img {
        height: 25px;
    }

    .skill--box h3 {
        font-size: 14px;
        margin-top: 1rem;
    }

    .marquee {
        padding: 0rem 0 2rem 0;
    }

}

@media screen and (max-width: 300px) {
    
    .skillsContainer {
        padding: 0 1rem;
    }

    .skillsHeader {
        margin-bottom: 0;
    }

    .skillsHeader > h2{
        font-size: 1.8rem;
    }


    .skill--box {
        width: 75px;
        height: 75px;
        margin: .8rem;
        padding: 1rem 0rem;
    }

    .skill--box > img {
        height: 25px;
    }

    .skill--box h3 {
        font-size: 14px;
        margin-top: 1rem;
    }

    .skills {
        padding: 0rem .5rem;
        margin-bottom: 0rem;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}