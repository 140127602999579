.about {
    height: 850px;
    position: relative;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.about-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 8rem;
    height: fit-content;
}

.about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    flex: 0.5;
    width: 100%;
    height: fit-content;
}

.about-description >h2 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.about-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
    text-align: justify;
}

.about-img {
    margin-top: 40px;
    pointer-events: none;
}

.about-img > img {
    width: 380px;
    flex: 0.5;
}

@media screen and (max-width: 1500px) {

    .about {
      height: 850px;
    }

    .about-description > h2 {
      font-size: 3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }

    .about-img>img {
        width: 300px;
    }

    .about-description {
      flex: .5 1;
    }
}

@media screen and (max-width: 1200px) {

  .about-description {
    flex: .7 1;
  }

}

@media screen and (max-width: 992px){

  .about .line-styling{
    top: 20px;
  }

  .about {
    height: 950px;
  }

    .about-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
        margin: 0rem 3rem;
    }

    .about-description{
        flex: 0.6;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .about-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
        align-self: center;
        margin-top: 3rem;
    }

    .about-description > p {
        font-size: 1.1rem;
    }

    .about-img > img {
        width: 250px;
        flex: 0.4;
    }

}

@media screen and (max-width: 800px){
  .about .line-styling .style-circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .about-body {
    margin: 0rem 2rem;
  }

  .about {
    height: 1000px;
  }

}

@media screen and (max-width: 700px){
  .about {
    height: 1100px;
  }
}

@media screen and (max-width: 600px){

  .about .line-styling .style-circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .about .line-styling .style-line{
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .about-description > h2 {
    font-size: 2.5rem;
  }

  .about-description > p{
      font-size: 0.95rem;
    }

  .about-img > img {
        width: 200px;
    }
}

@media screen and (max-width: 500px){
  .about-description > h2 {
    font-size: 2.1rem;
  }

  .about-description > p {
    font-size: 16px;
    text-align: justify;
  }

}

@media screen and (max-width: 430px){
  .about {
    height: 1200px;
  }

}

@media screen and (max-width: 400px){
  .about {
    height: 1100px;
  }

  .about-description > h2 {
    font-size: 1.8rem;
    margin-top: 2rem;
  }

  .about-description > p {
    font-size: 14px;
    text-align: justify;
  }

}

@media screen and (max-width: 340px){
  .about {
    height: 1250px;
  }
}

@media screen and (max-width: 300px){

  .about-description > p {
    font-size: 12px;
    text-align: justify;
  }

  .about-body {
    padding-top: 2rem;
  }

  .about-img {
    margin-top: 10px;
    pointer-events: none;
}

}